import React from 'react'
import '../App.css'
import '../media.css'

function conv(price) {
  return new Intl.NumberFormat('ru-Ru', { style: 'currency', currency: 'RUB' }).format(price)
}

const serviceArray = [
  {
    title: 'Регистрация товарного знака',
    text: 'Помощь с подачей заявки в Роспатент. Получение решения о регистрации товарного знака в обычном и ускоренном режиме.',
    price: 26600
  },
  {
    title: 'Патентование',
    text: 'Проверка и подача документов в ФИПС. Оформление патентов на изобретения, промышленные образцы и полезные модели.',
    price: 35000
  },
  {
    title: 'Суды и споры',
    text: 'Урегулирование сложных конфликтных ситуаций. Решение споров об авторстве инновации, установление патентообладателя.',
    price: 35000
  },
  {
    title: 'Договоры',
    text: 'Составление договоров для передачи прав и предоставления франшизы. Юридические консультации патентных проверенных.',
    price: 25000
  },
  {
    title: 'Авторское право',
    text: 'Регистрация, признание, защита в суде и официальное закрепление авторских прав. Взыскание ответственности с нарушителей.',
    price: 20000
  },
  {
    title: 'Регистрация программ для ЭВМ',
    text: 'Комплексная работа с компьютерными программами, мобильными приложениями, доменными именами и IT-решениями.',
    price: 25000
  }]

export default function Services() {
  return (
    <div className='container_body shrink-body'>
      <p className='title my-5 font-bold text-center xl:my-2'>Услуги</p>
        <div className='grid grid-cols-3 gap-20 my-20 xl:my-14 lg:my-10 md:my-6 xs:my-3 xl:gap-14 lg:gap-10 md:gap-4 sm:gap-3 xs:gap-2 md:grid-cols-2 sm:flex sm:flex-wrap'>
          {serviceArray.map((item, i)=><li className='' key={i}><p className='comp_title'>{item.title}</p><p>{item.text}</p><p className='pt-3'>от {conv(item.price)}</p></li>)}
        </div>
    </div>
  )
}

